<!--
 * @Description: 车行道停车运营管理 运营管理 黑名单告警 blackListWarn
 * @Author: zhoucheng
 * @Github: https://github.com/zhoucheng-tt
 * @Date: 2021-08-30 16:29:41
 * @LastEditors: zhoucheng
-->
<template>
  <div class='mainBody'>
    <el-row class="topSelectLine">
      <el-form :inline="true"
               :model="searchForm">
        <el-row>
          <el-col :span="6">
            <el-form-item label-width="90px"
                          label="告警编号">
              <el-input v-model="searchForm.alarmRecordId"
                        clearable
                        placeholder="请输入告警编号"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="告警时间"
                          label-width="90px">
              <el-date-picker v-model="alarmTime"
                              type="daterange"
                              align="right"
                              unlink-panels
                              value-format="yyyy-MM-dd HH:mm:ss"
                              range-separator="-"
                              start-placeholder="开始日期"
                              end-placeholder="结束日期"
                              :default-time="['00:00:00', '23:59:59']"
                              :picker-options="pickerOptions">
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="告警地点"
                          label-width="90px">
              <el-input v-model="searchForm.address"
                        clearable
                        placeholder="请输入告警地点"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label-width="90px"
                          label="车牌号">
              <el-input v-model="searchForm.plateNumber"
                        clearable
                        placeholder="请输入车牌号"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="topSecondLine">
          <el-col :span="6">
            <el-form-item label-width="90px"
                          label="车牌颜色"
                          prop="numberPlateColorCode">
              <el-select v-model="searchForm.numberPlateColorCode"
                         class="dt-form-width"
                         placeholder="请选择">
                <el-option v-for="(item, index) in colorList"
                           :key="index"
                           :label="item.name"
                           :value="item.code">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="topButton">
          <el-button @click="queryButton"
                     type="primary">查 询</el-button>
          <el-button @click="resetForm"
                     type="info"
                     style="margin-left:17px">重 置</el-button>
        </el-row>
      </el-form>
    </el-row>
    <!-- 表格 -->
    <el-row class="tableRow">
      <el-row class="tableTitle">
        <span> 黑名单告警 </span>
        <el-button type="info"
                   class="tableTitleButton"
                   @click="exportFile">
          导 出
        </el-button>
      </el-row>
      <el-row class="tableContent">
        <dt-table ref="table"
                  :tableColumnList="tableColumnList"
                  :data="tableList"
                  :map="listMap"
                  :tableConfig='tableConfig'
                  :paginationConfig='paginationConfig'
                  @getList="getList">
          <template slot="operating">
            <el-table-column label="操作">
              <template slot-scope="scope">
                <el-row>
                  <el-col>
                    <el-button @click="detailsClick(scope.row)"
                               type="text"
                               size="medium">详情</el-button>
                  </el-col>
                </el-row>
              </template>
            </el-table-column>
          </template>
        </dt-table>
      </el-row>
    </el-row>
    <!-- 告警详情弹窗 -->
    <el-dialog :title="dialogTitle"
               :visible.sync="dialogVisible"
               :close-on-click-modal="false"
               append-to-body>
      <el-form label-width="150px"
               :model="popForm"
               ref="popForm"
               :inline="true"
               size="small">
        <el-row justify="space-around">
          <el-col :span="12">
            <el-form-item label="告警编号"
                          prop="alarmRecordId">
              <el-input v-model="popForm.alarmRecordId"
                        readonly
                        class="dt-form-width"
                        placeholder=""></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="告警时间"
                          prop="alarmTime">
              <el-input v-model="popForm.alarmTime"
                        readonly
                        class="dt-form-width"
                        placeholder=""></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row justify="space-around">
          <el-col :span="12">
            <el-form-item label="车牌号"
                          prop="plateNumber">
              <el-input v-model="popForm.plateNumber"
                        readonly
                        class="dt-form-width"
                        placeholder=""></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="车牌颜色"
                          prop="plateColor">
              <el-input v-model="popForm.plateColor"
                        readonly
                        class="dt-form-width"
                        placeholder=""></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="告警地点"
                          prop="address">
              <el-input v-model="popForm.address"
                        readonly
                        class="dt-form-width"
                        placeholder=""></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="所属街道"
                          prop="jiedao">
              <el-input v-model="popForm.jiedao"
                        readonly
                        class="dt-form-width"
                        placeholder=""></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="所属路段"
                          prop="road">
              <el-input v-model="popForm.road"
                        readonly
                        class="dt-form-width"
                        placeholder=""></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="泊位"
                          prop="berth">
              <el-input v-model="popForm.berth"
                        readonly
                        class="dt-form-width"
                        placeholder=""></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-form-item label="进场图片">
            <div class="block">
              <el-col :span="8">
                <el-image style="width: 200px; height: 200px"
                          :src="$imgBaseUrl + popForm.picUrl"
                          fit="contain"></el-image>

              </el-col>
            </div>
          </el-form-item>
        </el-row>
      </el-form>
      <el-row type="flex"
              justify="end">
        <el-col :span="12"
                class="dt-button-box">
          <el-button @click="dialogVisible = false"
                     type="info">返 回</el-button>
        </el-col>
      </el-row>
    </el-dialog>
  </div>

</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import dtTable from '@/components/table.vue'
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {
    dtTable
  },
  data () {
    //这里存放数据
    return {
      tableConfig: { border: true },
      paginationConfig: { layout: 'prev,sizes,pager,next,jumper', total: 0 },
      listMap: { pageNum: 1, pageSize: 15, },
      tableList: { list: [] },
      searchForm: {}, // 搜索内容
      pageNum: 1, // 分页
      pageSize: 15,
      tableColumnList: [
        {
          prop: 'alarmRecordId',
          label: '告警编号',
        },
        {
          prop: 'alarmTime',
          label: '告警时间',
        },
        {
          prop: 'plateNumber',
          label: '车牌号',
        },
        {
          prop: 'plateColor',
          label: '车牌颜色',
        },
        {
          prop: 'address',
          label: '告警地点',
        }
      ],
      // 时间数组
      alarmTime: [],
      // 车牌颜色下拉框
      colorList: [],
      dialogTitle: '',// 弹出框标题
      //详情弹窗
      popForm: {},
      dialogVisible: false, //弹出框显示
      //日期快速选择
      pickerOptions: {
        shortcuts: [
          {
            text: '最近一周',
            onClick (picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', [start, end])
            },
          },
          {
            text: '最近一个月',
            onClick (picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [start, end])
            },
          },
          {
            text: '最近三个月',
            onClick (picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
              picker.$emit('pick', [start, end])
            },
          },
        ],
      },
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //生命周期 - 创建完成（可以访问当前this实例）
  created () {

  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    this.queryTableList()
    this.getColorDict()
  },
  beforeCreate () { }, //生命周期 - 创建之前
  beforeMount () { }, //生命周期 - 挂载之前
  beforeUpdate () { }, //生命周期 - 更新之前
  updated () { }, //生命周期 - 更新之后
  beforeDestroy () { }, //生命周期 - 销毁之前
  destroyed () { }, //生命周期 - 销毁完成
  activated () { }, //如果页面有keep-alive缓存功能，这个函数会触发
  //方法集合
  methods: {
    // 导出
    exportFile () {
      this.$axios.get(this.$downloadBaseUrl + "blackAlarm/download", {
        responseType: "blob",
        headers: {
          'userToken': localStorage.getItem("userToken")
        }
      }).then(res => {
        let blob = new Blob([res.data], {
          type: "application/vnd.ms-excel",
        }); // 2.获取请求返回的response对象中的blob 设置文件类型，这里以excel为例
        let url = window.URL.createObjectURL(blob); // 3.创建一个临时的url指向blob对象
        // 4.创建url之后可以模拟对此文件对象的一系列操作，例如：预览、下载
        let a = document.createElement("a");
        a.href = url;
        a.download = "黑名单告警.xlsx";
        a.click();
        // 5.释放这个临时的对象url
        window.URL.revokeObjectURL(url);
      })
    },
    // 获取数据
    queryTableList () {
      this.searchForm['pageNum'] = this.pageNum
      this.searchForm['pageSize'] = this.pageSize
      this.$blackListWarn.queryBlackAlarmRecordList(this.searchForm).then(res => {
        this.tableList.list = res.resultEntity.list
        this.paginationConfig.total = res.resultEntity.total
      })
    },
    //分页
    getList (change) {
      this.pageSize = change.pageSize
      this.pageNum = change.pageNum
      this.queryTableList()
    },
    // 查询
    queryButton () {
      this.pageNum = 1
      this.listMap.pageNum = 1
      if (this.alarmTime) {
        this.searchForm.startTime = this.alarmTime[0]
        this.searchForm.endTime = this.alarmTime[1]
      }
      else {
        this.searchForm.startTime = ''
        this.searchForm.endTime = ''
      }
      this.queryTableList()
    },
    // 重置
    resetForm () {
      this.pageNum = 1
      this.listMap.pageNum = 1
      this.searchForm = {}
      this.alarmTime = []
      this.queryTableList()
    },
    // 点击详情
    detailsClick (row) {
      this.dialogVisible = true
      this.popForm = row
      this.disabled = true
      this.dialogTitle = "告警详情"
    },
    //获取车牌颜色
    getColorDict () {
      let info = {
        columnName: ['value', 'name'],
        tableName: 'tb_dict',
        whereStr: [
          {
            colName: 'parent_id',
            value: '204E657017184ADFAE6E39629B38DDC2',
          },
        ],
      }
      this.$queryDict.queryDict(info).then((response) => {
        this.colorList = response.resultEntity
      })
    },
  },
}
</script>
<style lang='scss' scoped>
.mainBody {
  height: 100%;
  .topSelectLine {
    width: 100%;
    @include background('blockBg');
    padding-top: 24px;
    padding-bottom: 24px;
    padding-left: 21px;
    .topSecondLine {
      margin-top: 19.7px;
    }
    .topButton {
      display: flex;
      padding-left: 91px;
      margin-top: 17px;
    }
  }
  .tableRow {
    width: 100%;
    @include background('blockBg');
    margin-top: 16px;
    padding-left: 20.2px;
    padding-right: 20.2px;
    padding-top: 5px;
    .tableTitle {
      height: 32px;
      margin-top: 15px;
      .tableTitleButton {
        float: right;
      }
    }
    .tableContent {
      margin-top: 10px;
    }
  }
}
.dt-button-box {
  display: flex;
  justify-content: flex-end;
}
</style>